@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-cloudear-base;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

div:focus {
  outline: none;
}

div[data-tippy-root] {
  max-width: 75%;
}
